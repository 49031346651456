import React, {createContext} from "react";
import {User} from "../../types/user";

export type AuthContextType = {
    user: User | null;
    signin: (email:string, password: string, empresa: string) => Promise<Boolean>;
    signout: () => void;
    signoutSemAPi: () => void;
    atualizarListaDocumentos: number | null;
    documentoSemVisualizacao: number | null;
    setDocumentoSemVisualizacao(number: any) : void;
    setAtualizarListas(number: any) : void;
    themeMode: boolean | null;
    setThemeMode(theme: boolean) : void;
}
export const AuthContext = createContext<AuthContextType>(null!);