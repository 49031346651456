import axios from 'axios';
import {useEffect} from "react";
import {Documento} from "../types/documento";


const api = axios.create({
    baseURL: process.env.REACT_APP_API
})
const IA = axios.create({
    baseURL: "https://llama2.pdg.dev/"
})
export const useApi = (token: any) => ({

    validateToken: async (token: string) => {

            const response = await api.post('/validate-token', {}, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;


    },
    signin: async (email: string, password: string, empresa: string) => {
        try {
            const response = await api.post('/auth/login', {email, password, empresa}, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "Authorization",
                    "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, PATCH, DELETE",
                    "Content-Type": "application/json;charset=UTF-8",
                    "Accept": "application/json"
                }
            });
            return response.data;
        }catch (e) {
            return e
        }


    },
    signin_two_factor: async (email: string) => {
        try {
            const response = await api.post('/two-factor/email-two-factor', {email}, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "Authorization",
                    "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, PATCH, DELETE",
                    "Content-Type": "application/json;charset=UTF-8",
                    "Accept": "application/json"
                }
            });
            return response.data;
        }catch (e) {
            return e
        }


    },
    logout: async () => {

        const response = await api.get('/logout', {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response.data;
    },
    perfil: {

        insert: async ( descricao: string, nivel: number, nome_perfil: string) => {
            try{
                return await api.post('/admin/perfil', {descricao, nivel, nome_perfil}, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                });
            }catch(error) {
              throw error;
            }


        },
        readAll: async () => {

            let response = await api.get('/admin/perfil', {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;
        },
        readOne: async (perfil_id: number) => {
            let response = await api.get(`/admin/perfil/${perfil_id}`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;
        },
        delete: async (perfil_id: number) => {
            let response = await api.delete(`/admin/perfil/${perfil_id}`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;
        },
        update: async (perfil_id: number, descricao: string, nome_perfil: string, nivel: number) => {
            let response = await api.put(`/admin/perfil/${perfil_id}`, {descricao, nome_perfil, nivel}, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;
        },
    },
    usuario: {
        insert: async (setor_id: string, name: string, email: string, perfil_id: string, tratamento: string,
                       inicio_mandato: string, fim_mandato: string, aniversario: string, telefone_comercial: string, telefone_residencial: string, telefone_celular: string, senha: string) => {

            return await api.post('/admin/usuario', {
                setor_id,
                name,
                email,
                perfil_id,
                tratamento,
                inicio_mandato,
                fim_mandato,
                aniversario,
                telefone_comercial,
                telefone_residencial,
                telefone_celular,
                senha
            }, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });

        },
        usuarioComLabelValue: async () => {
            let response = await api.get('/usuario-label-value', {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;
        },
        readAll: async () => {
            let response = await api.get('/admin/usuario', {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;
        },
        readOne: async (usuario_id: number) => {
            let response = await api.get(`/admin/usuario/${usuario_id}`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;
        },
        verificar_email: async (email: string) => {
            let response = await api.post(`/verificar-email/`, {
                email
            },{
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;
        },
        delete: async (usuario_id: number) => {
            let response = await api.delete(`/admin/usuario/${usuario_id}`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;
        },
        update: async (setor_id: string, usuario_id: number, name: string, email: string, perfil_id: string, tratamento: string,
                       inicio_mandato: string, fim_mandato: string, aniversario: string, telefone_comercial: string, telefone_residencial: string, telefone_celular: string, senha: string) => {
            let response = await api.put(`/admin/usuario/${usuario_id}`, {
                setor_id,
                name,
                email,
                perfil_id,
                tratamento,
                inicio_mandato,
                fim_mandato,
                aniversario,
                telefone_comercial,
                telefone_residencial,
                telefone_celular,
                senha
            }, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;
        },
    },
    transacao_documento:{
        readAll: async () => {
            let response = await api.get('/transicao_documento', {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;
        },
    },
    tipo_documento: {
        insert: async (descricao: string, passo: string) => {
            return await api.post('/tipo-documento', {descricao, passo}, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });

        },
        readAll: async () => {
            let response = await api.get('/tipo-documento', {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;
        },
        readOne: async ( tipo_documento_id: number) => {
            let response = await api.get(`/tipo-documento/${tipo_documento_id}`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;
        },
        delete: async (tipo_documento_id: number) => {
            let response = await api.delete(`/tipo-documento/${tipo_documento_id}`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;
        },
        update: async (tipo_documento_id: number, descricao: string, passo: string) => {
            let response = await api.put(`/tipo-documento/${tipo_documento_id}`, {descricao, passo}, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;
        },
    },
    reuniao: {
        insert: async (descricao: string, data: string, horario: string, participantes: string, pauta: string) => {
            return await api.post('/reuniao', {descricao, data, horario, participantes, pauta}, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });

        },
        readAll: async () => {
            let response = await api.get('/reuniao', {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;
        },
        readOne: async (reuniao_id: number) => {
            let response = await api.get(`/reuniao/${reuniao_id}`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;
        },
        delete: async (reuniao_id: number) => {
            let response = await api.delete(`/reuniao/${reuniao_id}`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;
        },
        update: async (reuniao_id: number, descricao: string, data: string, horario: string, participantes: string, pauta: string) => {
            let response = await api.put(`/reuniao/${reuniao_id}`, {descricao, data, horario, participantes, pauta}, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;
        },
    },
    empresa: {
        readAll: async () => {
            let response = await api.get('/empresas', {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;
        },
    },
    setor: {
        readAll: async () => {
            let response = await api.get('/setores', {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;
        },
        setorComValueLabel: async () => {
            let response = await api.get('/setor-label-value', {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;
        },
    },
    processo:{
        readAll: async () => {
            let response = await api.get('/processos/listar-todos', {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;
        },
        readWithFilter: async (filtros: any) => {
            let response = await api.post('/listar-processos-filtro', {
                filtros
            },{
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;
        },
        processoParaDocumentos: async () => {
            let response = await api.get('/processos/processo-para-documentos', {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;
        },
        montarDocumentosDoProcesso: async (processo: any) => {
            try {
                let response = await api.get(`/montar-documentos-processo/${processo}`, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                });
                return response;
            }catch (e) {

            }

        },
        insert: async (descricao: string, confidencial: boolean, visibilidade: boolean, passo_atual: string, setor_id: string, perfil_minimo_id: string, observacao: string) => {
            return await api.post('/processo', {descricao, confidencial, visibilidade, passo_atual, setor_id, perfil_minimo_id, observacao}, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });

        },
        update: async (processoId:any, descricao: string, confidencial: boolean, visibilidade: boolean, setor_id: string, perfil_minimo_id: string, observacao: string) => {
            return await api.put('/processo/'+ processoId , {descricao, confidencial, visibilidade, setor_id, perfil_minimo_id, observacao}, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });

        },
        readOne: async (processo_id: string) => {
            let response = await api.get(`/processo/${processo_id}`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;
        },
        concluirProcesso: async (processo_id: string) => {
            let response = await api.get(`/processos/concluir-processo/${processo_id}`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;
        },
        reabrirProcesso: async (processo_id: string) => {
            let response = await api.get(`/processos/reabrir-processo/${processo_id}`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;
        },
        destroy: async (processo_id: string) => {
            let response = await api.delete(`/processo/${processo_id}`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;
        },
    },
    documento: {
        insert: async (confidencial: number, resposta_documento_id: string, setor_id:string, tipo_documento_id: string,/* reuniao_id: string,*/ destinatario_user_id: string,
                       corpo_texto: string,  titulo: string, descricao: string,
                       dias: string, processo_id: string, enviar_todos:boolean) => {
            return await api.post('/documento', {confidencial, resposta_documento_id, setor_id, tipo_documento_id, /*reuniao_id,*/ destinatario_user_id,
                corpo_texto, titulo, descricao, dias, processo_id, enviar_todos}, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });

        },
        readAll: async () => {
            let response = await api.get('/documento', {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;
        },
        grupos_documento: async () => {
            let response = await api.get('/grupos-documento', {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;
        },
        readWithFilter: async (filtros: any) => {
            let response = await api.post('/listar-documentos-filtro', {
                filtros
            },{
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;
        },
        documentoPorProcesso: async (processo_id: any) => {
            let response = await api.get(`/documento-por-processo/${processo_id}`,{
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;
        },
        TemDocumentosSemVisualizacao: async () => {
            let response = await api.get('/tem-documento-sem-visualizacao', {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;
        },
        readOne: async (documento_id: string) => {
            let response = await api.get(`/documento/${documento_id}`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;
        },
        delete: async (documento_id: string) => {
            try {
                let response = await api.delete(`/documento/${documento_id}`, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                });
                return response;
            }  catch(error) {
            throw error;
        }

        },
        update: async (confidencial: number, setor_id:string, documento_id: string, tipo_documento_id: string, /*reuniao_id: string,*/ destinatario_user_id: string,
                       corpo_texto: string,  titulo: string, descricao: string,
                       dias: string, enviar_todos: boolean,processo_id: any) => {
            let response = await api.put(`/documento/${documento_id}`, {
                confidencial, setor_id, tipo_documento_id, /*reuniao_id,*/ destinatario_user_id,
                corpo_texto, titulo, descricao, dias, enviar_todos,processo_id
            }, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;
        },
        registra_visualizacao: async (documento_id: string) => {
            let response = await api.get(`/visualizar-documento/${documento_id}`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;
        },
        pegarHistoricoPorDocumento: async ( documento_id: string) => {
            let response = await api.get(`/pegar-historico-por-documento/${documento_id}`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;
        },
        pegarPassosDocumento: async ( documento_id: string) => {
            let response = await api.get(`/passos-documento/${documento_id}`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;
        },
    },
    documento_historico: {
        mostrarMudanca: async ( documento_id: string) => {
            let response = await api.get(`/mostrar-mudanca-documento/${documento_id}`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;
        },
    },
    contato: {
        insert: async (nome: string, email: string, motivo: string, mensagem: string) => {
            return await api.post('/contato', {nome, email, motivo, mensagem});
        }
    },
    IA: {
        teste: async () => {
            return await IA.post('v1/completions', {
                "prompt": "1+1?",
                "temperature": 0.1,
            }, {
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "Authorization",
                }
            });
        }
    },
    IA2:{
        teste: async (pergunta: string) => {
           let data = {"contents":[{"parts":[{"text":pergunta}]}]}
            let response = await fetch("https://generativelanguage.googleapis.com/v1beta/models/gemini-pro:generateContent?key=AIzaSyCEdqYK3wDuw9_cCBIBZyyWhWCy8Rw1jSg", {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                    headers: {
                    "Content-Type": "application/json",
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify(data), // body data type must match "Content-Type" heade
            })
            return response.json();
        }
    },
    relatorio: {
        DocumentoPorSetor: async (dataInicio: string, dataFinal: string) => {
            let response = await api.post('/relatorio/relatorio-documento-por-setor', {
                dataInicio, dataFinal
            }, {

                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;
        },
    },
    dashboard: {
        totais: async () => {
            let response = await api.get('/dashboard/totais',  {

                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;
        },
    },
    grafico: {
        GraficoDocumentoPorTipo: async (dataInicio: string, dataFinal: string) => {
            let response = await api.post('/grafico/grafico-documento-por-tipo', {
                dataInicio, dataFinal
            }, {

                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;
        },
        GraficoProcessoPorPassoEmAndamento: async (dataInicio: string, dataFinal: string) => {
            let response = await api.post('/grafico/grafico-processo-por-passo-em-andamento', {
                dataInicio, dataFinal
            }, {

                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;
        },
        GraficoDocumentoPorSetor: async (dataInicio: string, dataFinal: string) => {
            let response = await api.post('/grafico/grafico-documento-por-setor', {
                dataInicio, dataFinal
            }, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;
        },
        GraficoDocumentoPorPassoEmAndamento: async (dataInicio: string, dataFinal: string) => {
            let response = await api.post('/grafico/grafico-documento-por-passo-em-andamento', {
                dataInicio, dataFinal
            }, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            return response;
        }
    }
});