import React, {useContext} from "react";
import {Link, useNavigate} from "react-router-dom";
import {AuthContext} from "../contexts/Auth/AuthContext";

import {
    faChartPie,
    faEnvelope, faFileWaveform,
    faFolderOpen, faFolderTree,
    faHouse,
    faIdCardClip,
    faUsersGear,
    faUsersViewfinder,
    faLaptopFile, faFileInvoice
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Tooltip} from "react-tooltip";
import LogoSuperior from "./LogoSuperior";
import './Menu.css'
function MenuAdministrador() {
    const context = useContext(AuthContext);


    return (
        <nav className="navbar navbar-expand-lg navbar-light p-3" style={{background: '#f5f5f5'}}>
            <div className="container-fluid">
                <a className="navbar-brand" href="#"><LogoSuperior /></a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link className="nav-link text-lg-center " to="/"><FontAwesomeIcon className=" fa-2xl"
                                                                                               icon={faHouse}/>
                                <div className="mt-lg-1 item-menu d-none d-md-block ">Página Principal</div>
                                <span className="d-lg-none">Página Principal</span> </Link>
                        </li>
                        {
                            (context.user?.nivel === 3 || context.user?.nivel === 4 || context.user?.nivel === 10) &&
                            <li className="nav-item">
                                <Link className="nav-link text-lg-center" to="/dashboard"><FontAwesomeIcon
                                    className=" fa-2xl"
                                    icon={faLaptopFile}/>
                                    <div className="mt-lg-1 item-menu d-none d-md-block ">Dashboard</div>
                                    <span
                                        className="d-lg-none">Dashboard</span></Link>
                            </li>

                        }

                        {
                            (context.user?.nivel === 3 || context.user?.nivel === 4 || context.user?.nivel === 10) &&
                            <li className="nav-item">
                                <Link className="nav-link text-lg-center" to="/perfil"><FontAwesomeIcon
                                    className=" fa-2xl"
                                    icon={faIdCardClip}/>
                                    <div className="mt-lg-1 item-menu d-none d-md-block ">Perfil</div>
                                    <span
                                        className="d-lg-none">Perfil</span></Link>
                            </li>
                        }
                        {
                            (context.user?.nivel === 3 || context.user?.nivel === 4 || context.user?.nivel === 10) &&
                            <li className="nav-item">
                                <Link className="nav-link text-lg-center" to="/Usuario"><FontAwesomeIcon
                                    className=" fa-2xl"
                                    icon={faUsersViewfinder}/>
                                    <div className="mt-lg-1 item-menu d-none d-md-block ">Usuários</div>
                                    <span
                                        className="d-lg-none">Usuários</span></Link>
                            </li>
                        }
                        {
                            (context.user?.nivel === 3 || context.user?.nivel === 4 || context.user?.nivel === 10) &&
                            <li className="nav-item">
                                <Link className="nav-link text-lg-center" to="/tipo-documento"><FontAwesomeIcon
                                    className=" fa-2xl"
                                    icon={faFolderTree}/>
                                    <div className="mt-lg-1 item-menu d-none d-md-block ">Tipo de Documentos</div>
                                    <span
                                        className="d-lg-none">Tipo de Documentos</span></Link>
                            </li>
                        }
                        {/*
                            <li className="nav-item">
                                <Link className="nav-link text-lg-center" to="/reuniao"><FontAwesomeIcon
                                    className=" fa-2xl"
                                    icon={faUsersGear}/>
                                    <div className="mt-lg-1 item-menu d-none d-md-block ">Reunião</div>
                                    <span
                                        className="d-lg-none">Reunião</span></Link>
                            </li>*/
                        }
                        {
                            (context.user?.nivel === 3 || context.user?.nivel === 4 || context.user?.nivel === 10) &&
                            <li className="nav-item">
                                <Link className="nav-link text-lg-center position-relative" to="/processo">
                                    <FontAwesomeIcon className=" fa-2xl"
                                                     icon={faFolderOpen}/>
                                    <div className="mt-lg-1 item-menu d-none d-md-block ">Processos</div>
                                    <span
                                        className="d-lg-none">Processos</span></Link>
                            </li>
                        }

                        <li className="nav-item">
                            <Link className="nav-link text-lg-center position-relative" to="/documento">  <span
                                className="position-absolute top-0 start-100 translate-middle p-1 bg-danger border border-light rounded-circle text-white "
                                style={{fontSize: '10px'}}>0{context.documentoSemVisualizacao}
                                <span className="visually-hidden">New alerts</span>
  </span>
                                <FontAwesomeIcon className=" fa-2xl"
                                                 icon={faFileInvoice}/>
                                <div className="mt-lg-1 item-menu d-none d-md-block ">Documentos</div>
                                <span
                                    className="d-lg-none">Documentos</span></Link>
                        </li>
                        {
                            (context.user?.nivel === 3 || context.user?.nivel === 4 || context.user?.nivel === 10) &&
                            <li className="nav-item">
                                <Link className="nav-link text-lg-center" to="/graficos"><FontAwesomeIcon
                                    className=" fa-2xl"
                                    icon={faChartPie}/>
                                    <div className="mt-lg-1 item-menu d-none d-md-block ">Gráfico</div>
                                    <span
                                        className="d-lg-none">Gráfico</span></Link>
                            </li>
                        }
                        {
                            (context.user?.nivel === 3 || context.user?.nivel === 4 || context.user?.nivel === 10) &&
                            <li className="nav-item">
                                <Link className="nav-link text-lg-center" to="/relatorios"><FontAwesomeIcon
                                    className=" fa-2xl"
                                    icon={faFileWaveform}/>
                                    <div className="mt-lg-1 item-menu d-none d-md-block ">Relatórios</div>
                                    <span
                                        className="d-lg-none">Relatórios</span></Link>
                            </li>
                        }
                        <li className="nav-item">
                            <Link className="nav-link text-lg-center" to="/contato"><FontAwesomeIcon className=" fa-2xl"
                                                                                                     icon={faEnvelope}/>

                                <div className="mt-lg-1 item-menu d-none d-md-block ">Contato</div>
                                <span
                                    className="d-lg-none">Contato</span></Link>
                        </li>

                    </ul>
                </div>
            </div>


        </nav>
    )
}

export default MenuAdministrador;