import React, {useContext, useEffect, useState} from 'react';

import {useNavigate, useParams} from "react-router-dom";
import {AuthContext} from "../../contexts/Auth/AuthContext";
import {useApi} from "../../hooks/useApi";
import Loading from "../../components/Loading";
import BarraProgressoDocumento from "../../components/BarraProgressoDocumento";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faLock, faLockOpen, faPenNib, faTrash} from "@fortawesome/free-solid-svg-icons";

export default function VisualizarTodosDocumentoProcesso() {
    let {processo_id} = useParams();

    let navigate = useNavigate();
    let auth = useContext(AuthContext)
    let token = localStorage.getItem('authToken')
    let api = useApi(token);
    const [documentos, setDocumentos] = useState([])


    const [loading, setLoading] = useState(false);
    useEffect(() => {

        handleDocumentos(processo_id)
    }, [processo_id]);


    const handleDocumentos = async (processo_id: string | undefined) => {
        setLoading(true)
        try {
            let response = await api.processo.montarDocumentosDoProcesso(processo_id);
            // @ts-ignore
            setDocumentos(response.data)
        } catch (error) {

        }

        setLoading(false)
    }

    function handleVoltar(e: any) {
        e.preventDefault();
        navigate('/documento')
    }


    return (

        <div className="font-verde p-5">
            <h1>Documentos pertencentes ao processo n {processo_id}</h1>
            <hr />
            <table >
            {
                documentos.map(({
                                    id,
                                    tipo_documento_id,
                                    reuniao_id,
                                    remetente_user_id,
                                    empresa_id,
                                    resposta_documento_id,
                                    corpo_texto,
                                    numero_documento,
                                    titulo,
                                    descricao,
                                    data_prazo,
                                    horario_prazo,
                                    protocolo,
                                    visualizacao,
                                    created_at,
                                    updated_at,
                                    transicao_documento_id,
                                    confidencial,
                                    nivel_usuario,
                                    processo_id,
                                    dias,
                                    deleted_at,
                               }) => (
                    <tr key={id} className="border-bottom border-danger">

                        <h1>{titulo}</h1>
                        <h5>N: {id}</h5>
                        <h5>Descrição: {descricao}</h5>
                        <h5>Remetente: {remetente_user_id}</h5>
                        <p> {corpo_texto}</p>
                        <p style={{color: 'red'}}>Fim do Documento</p>
                    </tr>

                ))

            }
            </table>
        </div>
    )
}