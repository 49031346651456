import React, {useContext, useEffect, useState} from "react";
import ContadorTextFiled from "../../components/ContadorTextField";
import "./processo.css"
import Loading from "../../components/Loading";
import {useApi} from "../../hooks/useApi";
import {useModal} from "../../hooks/useModal";
import {useNavigate, useParams} from "react-router-dom";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {Tooltip} from "react-tooltip";
import {modalRetorno} from "../../services/modalRetorno";
import {AuthContext} from "../../contexts/Auth/AuthContext";

const MySwal = withReactContent(Swal)

function ProcessoForm({processo}: any) {
    console.log(processo)
    let context = useContext(AuthContext)
    let token = localStorage.getItem('authToken')
    let api = useApi(token);
    let modal = useModal();
    let navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [setores, setSetores] = useState([]);
    const [perfis, setPefis] = useState([]);


    const [processoId, setProcessoId] = useState('')
    const [descricao, setDescricao] = useState('')
    const [observacao, setObservacao] = useState('')
    const [passoAtual, setPassoAtual] = useState('ABERTO')
    const [confidencial, setConfidencial] = useState(false)
    const [visibilidade, setVisibilidade] = useState(false)
    const [setorId, setSetorId] = useState('')
    const [perfilMinimoId, setPerfilMinimoId] = useState('')

    useEffect(() => {

        if (processo !== undefined) {
            setProcessoId(processo.id)
            setDescricao(processo.descricao)
            setObservacao(processo.observacao)
            setPassoAtual(processo.passo_atual)
            setConfidencial(processo.confidencial)
            setVisibilidade(processo.visibilidade)
            setSetorId(processo.setor_id)
            setPerfilMinimoId(processo.perfil_minimo_id)
        }
    }, [processo])


    useEffect(() => {
        handleSetores()
        handlePerfis()
    }, [])
    const handlePerfis = async () => {
        setLoading(true)
        let response = await api.perfil.readAll();
        setPefis(response.data);
        setLoading(false)
    }
    const handleSetores = async () => {
        setLoading(true)
        let response = await api.setor.readAll();
        setSetores(response.data);
        setLoading(false)
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        const response = await api.processo.insert(descricao, confidencial, visibilidade, passoAtual, setorId, perfilMinimoId, observacao);
        modalRetorno(response.status, "Processos")
        context.setAtualizarListas(parseInt(String(Math.random() * 20)))
    }

    const handleSubmitOne = async (e: any) => {
        e.preventDefault();

        const response = await api.processo.update(processoId, descricao, confidencial, visibilidade, setorId, perfilMinimoId, observacao);
        if (response.status === 200) {
            modal.timer("Processo", "Atualizado com sucesso!", "success");

        }
    }
    const handleReabrirProcesso = async (e: any, processo_id: any) => {
        e.preventDefault();
        MySwal.fire({
            title: "Reabrir Processo",
            text: "Tem certeza que deseja reabrir o processo?",
            icon: "warning",
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: true,
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
            confirmButtonColor: "#005f33",
            cancelButtonColor: "red"
        }).then(async (result) => {
            console.log(result)
            if (result.isConfirmed) {
                const response = await api.processo.reabrirProcesso(processo_id);
                if (response.status === 200) {
                    modal.timer("Reabrir Processo", "Processo reaberto com sucesso!", "success")
                    navigate('/processo')
                }

            }

        })
    }
    const handleFecharProcesso = async (e: any, processo_id: any) => {
        e.preventDefault();
        MySwal.fire({
            title: "Concluir Processo",
            text: "Tem certeza que deseja concluir o processo?",
            icon: "warning",
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: true,
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
            confirmButtonColor: "#005f33",
            cancelButtonColor: "red"
        }).then(async (result) => {
            console.log(result)
            if (result.isConfirmed) {
                const response = await api.processo.concluirProcesso(processo_id);
                if (response.status === 200) {
                    modal.timer("Concluir Processo", "Processo concluído com sucesso!", "success")
                    navigate('/processo')
                }

            }

        })
    }
    return (
        <div className="mb-5">
            {loading && <Loading/>}


            <form onSubmit={processoId === '' ? (e) => handleSubmit(e) : (e) => handleSubmitOne(e)}>


                <div className="row mb-4">
                    <div className="col  mt-3">
                        <div className="form-floating">
                        <textarea data-tooltip-id="my-tooltip" data-tooltip-content="Descrição"
                                  className="form-control corpo_texto" value={descricao}
                                  placeholder="Leave a comment here"
                                  id="floatingTextarea2"
                                  required
                                  maxLength={5000}
                                  onChange={(e) => setDescricao(e.target.value)}
                        ></textarea>
                            <label htmlFor="floatingTextarea2">Descrição</label>
                            <ContadorTextFiled texto={descricao} tamanho={5000}/>
                        </div>


                    </div>
                </div>

                <div className="row mb-4">

                    <div className="col-md-3 col-sm-12">
                        <label htmlFor="filterInput" className="form-label">Status</label>
                        <br/><span className={`${passoAtual} p-2 rounded`}>{passoAtual} </span>
                    </div>
                    <div className="col-md-3 col-sm-12">
                        <label htmlFor="filterInput" className="form-label">Setor</label>
                        <select className="form-select" aria-label="Default select example" required={true}
                                onChange={(e) => setSetorId(e.target.value)}
                                value={setorId}
                        >
                            <option selected value="">Selecione</option>
                            {setores.map(({id, descricao}) => (
                                <option key={id} value={id}>{descricao}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-3 col-sm-12">
                        <label htmlFor="filterInput" className="form-label">Perfil Mínimo</label>
                        <select className="form-select" aria-label="Default select example" required={true}
                                onChange={(e) => setPerfilMinimoId(e.target.value)}
                                value={perfilMinimoId}
                        >
                            <option selected value=''>Selecione</option>
                            {perfis.map(({id, descricao}) => (
                                <option key={id} value={id}>{descricao}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col  mt-3">
                        <div className="form-floating">
                        <textarea data-tooltip-id="my-tooltip" data-tooltip-content="Conteúdo da observação"
                                  className="form-control corpo_texto" value={observacao}
                                  placeholder="Leave a comment here"
                                  id="floatingTextarea2"
                                  required
                                  maxLength={5000}
                                  onChange={(e) => setObservacao(e.target.value)}
                        ></textarea>
                            <label htmlFor="floatingTextarea2">Observação</label>
                            <ContadorTextFiled texto={observacao} tamanho={5000}/>
                        </div>


                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-md-2 col-sm-12">
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault"
                                onChange={(e) => setConfidencial(e.target.checked)} checked={confidencial}/>
                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Confiabilidade
                            </label>
                        </div>
                    </div>
                    <div className="col-md-2 col-sm-12">
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault"
                                onChange={(e) => setVisibilidade(e.target.checked)} checked={visibilidade}/>
                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Visibilidade
                            </label>
                        </div>
                    </div>

                </div>
                <div className="row">

                    <div className="col-md-4">
                        <button type="submit"
                                className="btn btn-verde btn-primary btn-block mt-4 w-100">{processoId === '' ? "Salvar" : 'Atualizar'}</button>
                    </div>
                    <div className="col-md-4">
                        <button type="reset"
                                className="btn btn-verde btn-primary btn-block mt-4 w-100">Limpar
                        </button>
                    </div>
                    <div className="col-md-4">
                        {
                            passoAtual === "CONCLUIDO" &&
                            <button type="button"
                                    className="btn btn-info btn-block mt-4 w-100"
                                    onClick={(e) => handleReabrirProcesso(e, processoId)}>Reabrir Processo
                            </button>
                        }
                        {
                           passoAtual === "ABERTO" &&
                            <></>

                        }
                        {
                            passoAtual === "EM_ANDAMENTO" &&
                            <button type="button"
                                    className="btn btn-danger btn-block mt-4 w-100"
                                    onClick={(e) => handleFecharProcesso(e, processoId)}>Concluir Processo
                            </button>
                        }
                    </div>
                </div>
            </form>
            <Tooltip id="my-tooltip" className="d-none d-lg-block"/>
        </div>
    )
}

export default ProcessoForm;